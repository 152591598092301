import PropTypes from 'prop-types';
import { memo } from 'react';

import Box from 'shopper/components/Box';
import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import useClientSide from 'hooks/useClientSide';
import useLocalStorageState from 'hooks/useLocalStorageState';
import useMediaQuery from 'hooks/useMediaQuery';

import { sendEvent } from 'lib/gtag';

const OfferGridCardWithAd = ({
  description,
  gaEventCategory,
  id,
  image = null,
  isClosable = false,
  mobileImage = null,
  url,
}) => {
  const isClientSide = useClientSide();
  const { isMd } = useMediaQuery();
  const [isEnabledByLocalStorage, setEnabledByLocalStorage] =
    useLocalStorageState(`promobit.timeline.is-card-ad-${id}-enabled`, true);

  const onCloseClick = () => setEnabledByLocalStorage(false);

  const isDisabled =
    (!mobileImage && !image) ||
    (isClientSide
      ? !isEnabledByLocalStorage || (isMd && !image) || (!isMd && !mobileImage)
      : false);

  if (isDisabled) {
    return null;
  }

  return (
    <Box className="relative block overflow-hidden rounded-2 md:flex md:justify-between lg:hover:shadow-md lg:hover:transition-all">
      <Anchor
        className="flex h-full items-center justify-center"
        href={url}
        rel="noreferrer"
        target="_blank"
        onClick={() => {
          sendEvent({
            action: 'card_banner__click',
            category: gaEventCategory,
          });
        }}
      >
        <Image
          alt={description}
          className="h-[208px] max-h-[300px] w-full md:h-full md:max-h-none"
          height={208}
          sizes={['(max-width: 768px)', '(min-width: 768px)']}
          src={mobileImage}
          srcSet={[mobileImage, image]}
          width={343}
          priority
        />
      </Anchor>
      {isClosable && (
        <Button
          className="absolute right-0 top-0 mr-2 mt-2"
          icon={<Icon name="close" />}
          size="size4"
          type="secondary-stroke"
          onClick={onCloseClick}
        />
      )}
    </Box>
  );
};

OfferGridCardWithAd.propTypes = {
  description: PropTypes.string.isRequired,
  gaEventCategory: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  isClosable: PropTypes.bool,
  mobileImage: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default memo(OfferGridCardWithAd);
